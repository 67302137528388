<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="formattedDateTime"
        :label="label"
        :placeholder="placeholder"
        :error-messages="defaultedMessagesToDisplay"
        :disabled="disabled"
        :hide-details="dense"
        :prepend-icon="$icons.mdiCalendar"
        readonly
        clearable
        v-bind="attrs"
        v-on="on"
        @click:clear="clear"
      ></v-text-field>
    </template>

    <v-card>
      <v-card-text>
        <v-tabs
          v-model="activeTab"
          fixed-tabs
        >
          <v-tab>
            <v-icon class="mr-2">{{ $icons.mdiCalendar }}</v-icon>
            {{ dateTab }}
          </v-tab>
          <v-tab :disabled="timePickerTabDisabled">
            <v-icon class="mr-2">{{ $icons.mdiClockOutline }}</v-icon>
            {{ timeTab }}
          </v-tab>

          <v-tab-item>
            <v-date-picker
              v-model="date"
              :locale="locale"
              :max="maxDate"
              :min="minDate"
              :show-current="false"
              @input="handleDateInput"
            ></v-date-picker>
          </v-tab-item>

          <v-tab-item>
            <v-time-picker
              ref="timePicker"
              v-model="time"
              format="24hr"
              :max="maxTime"
              :min="minTime"
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="grey darken-2"
          text
          @click="close"
        >
          {{ cancelButton }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          text
          color="green darken-2"
          :disabled="isSubmitDisabled"
          @click="submit"
        >
          {{ submitButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import config from '@/config/config'

import { VInput } from 'vuetify/lib'

const locale = config.get('i18n.locale')

export default {
  name: 'TimeType',
  extends: VInput,
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    minDate: {
      type: String,
      default: undefined
    },
    maxDate: {
      type: String,
      default: undefined
    },
    minTime: {
      type: String,
      default: undefined
    },
    maxTime: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.date = this.dateValue

    if (this.timeValue.length > 0) {
      this.time = this.timeValue
    }
  },
  data () {
    return {
      locale,
      menu: false,
      time: '00:00',
      date: '',
      activeTab: 0,
      submitted: false
    }
  },
  methods: {
    handleDateInput () {
      this.activeTab = 1
    },
    submit () {
      this.$emit('input', this.mergedValue)

      this.submitted = true
      this.menu = false

      this.activeTab = 0
    },
    reset () {
      this.date = this.dateValue

      this.time = this.timeValue.length > 0 ? this.timeValue : '00:00'

      if (!this.$refs.timePicker) {
        return
      }

      this.$refs.timePicker.selecting = 1
    },
    close () {
      this.menu = false
      this.activeTab = 0
    },
    clear () {
      this.close()
      this.reset()

      this.$emit('input', '')
    }
  },
  computed: {
    dateValue () {
      if (this.value.length < 1) {
        return ''
      }

      return this.value.split('T')[0]
    },
    timeValue () {
      if (this.value.length < 1) {
        return ''
      }

      return this.value.split('T')[1]
    },
    mergedValue () {
      if (!this.date || !this.time) {
        return ''
      }

      return `${this.date}T${this.time}`
    },
    formattedDateTime () {
      if (!this.mergedValue) {
        return ''
      }

      const options = config.get('i18n.defaultLocalDateTimeStringOptions')
      return new Date(this.mergedValue).toLocaleString(locale, options)
    },
    defaultedMessagesToDisplay () {
      return this.messagesToDisplay ?? []
    },
    isSubmitDisabled () {
      return !this.mergedValue
    },
    dateTab () {
      return this.$t('global.components.dateTimeType.tabs.date')
    },
    timeTab () {
      return this.$t('global.components.dateTimeType.tabs.time')
    },
    cancelButton () {
      return this.$t('global.components.dateTimeType.buttons.cancel')
    },
    submitButton () {
      return this.$t('global.components.dateTimeType.buttons.submit')
    },
    timePickerTabDisabled () {
      return !this.date
    }
  },
  watch: {
    menu (open) {
      if (open) {
        this.reset()
      } else {
        if (this.submitted) {
          this.submitted = false
        } else {
          this.reset()
          this.activeTab = 0
        }
      }
    }
  }
}
</script>

<style scoped>
* >>> .v-label--active {
  pointer-events: none;
}
</style>
