var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"table-name":"memberships.table","data-table":_vm.dataTable,"actions":_vm.actions},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('help-dialog',{attrs:{"path":_vm.tableTitle,"tag":"span"}})],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',[_c('date-time-type',{attrs:{"label":_vm.showAtDateLabel,"placeholder":_vm.showAtDatePlaceholder,"dense":""},model:{value:(_vm.showAtDateTime),callback:function ($$v) {_vm.showAtDateTime=$$v},expression:"showAtDateTime"}})],1),_c('v-spacer'),_c('component-guard',{attrs:{"roles":['admin', 'manager']}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.openSidePanel}},[_vm._v(" "+_vm._s(_vm.addButtonText)+" ")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('copy',{attrs:{"text":item.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayText = ref.displayText;
return [_c('strong',[_vm._v(_vm._s(displayText))])]}}],null,true)}):_vm._e()]}},{key:"item.documentHandleUuid",fn:function(ref){
var item = ref.item;
return [_c('copy',{attrs:{"text":item.documentHandleUuid,"display":_vm.shortenUuid(item.documentHandleUuid)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var copy = ref.copy;
var displayText = ref.displayText;
return [_c('span',{class:_vm.getTextStyles(item)},[_vm._v(_vm._s(displayText))])]}}],null,true)})]}},{key:"item.currentDocumentVersion",fn:function(ref){
var item = ref.item;
return [(_vm.getDocumentVersionUUID(item))?_c('copy',{attrs:{"display":_vm.shortenUuid(_vm.getDocumentVersionUUID(item)),"text":_vm.getDocumentVersionUUID(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var copy = ref.copy;
var displayText = ref.displayText;
return [_c('span',{class:_vm.getTextStyles(item)},[_vm._v(_vm._s(displayText))])]}}],null,true)}):_c('span',[_vm._v("–")])]}},{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getTextStyles(item)},[_vm._v(_vm._s(_vm.createValidDateStringFromApi(item.validFrom)))])]}},{key:"item.validTo",fn:function(ref){
var item = ref.item;
return [(item.validTo)?[_c('span',{class:_vm.getTextStyles(item)},[_vm._v(_vm._s(_vm.createValidDateStringFromApi(item.validTo)))])]:_c('span',{class:_vm.getTextStyles(item)},[_vm._v("–")])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getTextStyles(item)},[_vm._v(_vm._s(item.comment))])]}}])}),_c('confirmation-dialog',{attrs:{"loading":_vm.deletingMembership,"title":_vm.deleteDialogTitle,"text":_vm.deleteDialogText,"cancel":_vm.deleteDialogCancelButton,"confirm":_vm.deleteDialogConfirmButton,"confirm-color":"red"},on:{"confirm":_vm.confirmDelete},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}}),_c('side-panel',{model:{value:(_vm.sidePanel),callback:function ($$v) {_vm.sidePanel=$$v},expression:"sidePanel"}},[_c('help-dialog',{attrs:{"tag":"h2","css-classes":"mb-8","path":_vm.editing ? _vm.formTitleEdit : _vm.formTitle}}),_c('form-builder',{attrs:{"config":_vm.formConfig,"data":_vm.form.data,"errors":_vm.form.errors,"rules":_vm.formRules,"transformers":_vm.form.transformers,"form-name":"memberships.form"},on:{"cancel":_vm.closeSidePanel,"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"form.footer",fn:function(ref){
var submit = ref.submit;
var valid = ref.valid;
return [_c('v-btn',{attrs:{"disabled":!valid || _vm.creatingMembership,"loading":_vm.creatingMembership,"color":"primary"},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.submitText)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }