<template>
  <div>
    <data-table
      table-name="memberships.table"
      :data-table="dataTable"
      :actions="actions"
    >
      <template #top>
        <v-toolbar flat>
          <v-toolbar-title>
            <help-dialog :path="tableTitle" tag="span"></help-dialog>
          </v-toolbar-title>

          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <div>
            <date-time-type
              v-model="showAtDateTime"
              :label="showAtDateLabel"
              :placeholder="showAtDatePlaceholder"
              dense
            ></date-time-type>
          </div>

          <v-spacer></v-spacer>

          <component-guard
            :roles="['admin', 'manager']"
          >

            <v-btn
              color="primary"
              @click="openSidePanel"
            >
              {{ addButtonText }}
            </v-btn>
          </component-guard>
        </v-toolbar>
      </template>

      <template #item.name="{ item }">
        <copy :text="item.name" v-if="item.name">
          <template #default="{ displayText }">
            <strong>{{ displayText }}</strong>
          </template>
        </copy>
      </template>

      <template #item.documentHandleUuid="{ item }">
        <copy :text="item.documentHandleUuid" :display="shortenUuid(item.documentHandleUuid)">
          <template #default="{ copy, displayText }">
            <span :class="getTextStyles(item)">{{ displayText }}</span>
          </template>
        </copy>
      </template>

      <template #item.currentDocumentVersion="{ item }">
        <copy
          v-if="getDocumentVersionUUID(item)"
          :display="shortenUuid(getDocumentVersionUUID(item))"
          :text="getDocumentVersionUUID(item)"
        >
          <template #default="{ copy, displayText }">
            <span :class="getTextStyles(item)">{{ displayText }}</span>
          </template>
        </copy>

        <span v-else>&ndash;</span>
      </template>

      <template #item.validFrom="{ item }">
        <span :class="getTextStyles(item)">{{ createValidDateStringFromApi(item.validFrom) }}</span>
      </template>

      <template #item.validTo="{ item }">
        <template v-if="item.validTo">
          <span :class="getTextStyles(item)">{{ createValidDateStringFromApi(item.validTo) }}</span>
        </template>

        <span
          v-else
          :class="getTextStyles(item)"
        >&ndash;</span>
      </template>

      <template #item.comment="{ item }">
        <span :class="getTextStyles(item)">{{ item.comment }}</span>
      </template>
    </data-table>

    <confirmation-dialog
      v-model="deleteDialog"
      :loading="deletingMembership"
      :title="deleteDialogTitle"
      :text="deleteDialogText"
      :cancel="deleteDialogCancelButton"
      :confirm="deleteDialogConfirmButton"
      confirm-color="red"
      @confirm="confirmDelete"
    ></confirmation-dialog>

    <side-panel v-model="sidePanel">
      <help-dialog
        tag="h2"
        css-classes="mb-8"
        :path="editing ? formTitleEdit : formTitle"
      ></help-dialog>

      <form-builder
        :config="formConfig"
        :data="form.data"
        :errors="form.errors"
        :rules="formRules"
        :transformers="form.transformers"
        form-name="memberships.form"
        @cancel="closeSidePanel"
        @submit="handleSubmit"
      >
        <template #form.footer="{ submit, valid }">
          <v-btn
            :disabled="!valid || creatingMembership"
            :loading="creatingMembership"
            color="primary"
            @click="submit"
          >
            {{ submitText }}
          </v-btn>
        </template>
      </form-builder>
    </side-panel>
  </div>
</template>

<script>
import { format, isPast } from 'date-fns'
import findLast from 'lodash/findLast'
import { mapActions, mapGetters, mapState } from 'vuex'

import ComponentGuard from '../ComponentGuard'
import ConfirmationDialog from '../ConfirmationDialog'
import Copy from '../Copy'
import DataTable from '../DataTable'
import DateTimeType from '../form/DateTimeType'
import FormBuilder from '../form/FormBuilder'
import HelpDialog from '../HelpDialog'
import SidePanel from '../SidePanel'

import { getHttpValidationErrorDetails } from '@/helpers/api.helper'
import {
  createValidDateStringFromPicker,
  createValidDateFromApi,
  createValidDateStringFromApi,
  getToday,
  // getTomorrow,
  currentTimeIsWithin,
  timeIsWithin
} from '@/helpers/datetime.helper'
import { minDate, required } from '@/helpers/validators.helpers'
import { shortenUuid } from '@/helpers/utility.helper'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'

export default {
  name: 'Memberships',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    ComponentGuard,
    ConfirmationDialog,
    Copy,
    DataTable,
    DateTimeType,
    FormBuilder,
    HelpDialog,
    SidePanel
  },
  props: {
    folder: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      createValidDateStringFromApi,
      form: {
        data: {
          document: '',
          validFrom: '',
          validTo: '',
          comment: ''
        },
        errors: {},
        transformers: {
          validFrom: (value) => createValidDateStringFromPicker(value, false, true),
          validTo: (value) => createValidDateStringFromPicker(value, false, true)
        }
      },
      formTitle: 'components.memberships.form.title',
      formTitleEdit: 'components.memberships.form.titleEdit',
      deleteDialog: false,
      deleteItem: null,
      editItem: null,
      showAtDateTime: '',
      memberships: []
    }
  },
  async created () {
    await this.getDocumentHandles({})
    await this.fetchMemberships()
  },
  methods: {
    ...mapActions('documentFolders', ['createMembership', 'getMemberships', 'deleteMembership', 'updateMembership', 'setDocumentFoldersSidePanel', 'setDocumentFoldersMembershipsSidePanel']),
    ...mapActions('documents', ['getDocumentHandles']),
    ...mapActions('messageQueue', ['queueSuccess', 'queueError']),
    openSidePanel (resetFormData = true) {
      if (resetFormData) {
        this.$set(this.form, 'data', {
          document: '',
          validFrom: '',
          validTo: '',
          comment: ''
        })

        this.editItem = null
      }

      this.sidePanel = true
    },
    closeSidePanel () {
      this.sidePanel = false
    },
    async handleSubmit ({ document, validFrom, validTo, comment }) {
      let success = true

      const handlers = {
        '2xx': () => {
          this.queueSuccess(this.successMessage)
          this.resetForm()

          this.fetchMemberships()
        },
        400: () => {
          success = false

          return this.$tp('components.memberships.form.messages.invalid')
        },
        422: (response) => {
          const errors = getHttpValidationErrorDetails(response)

          if (errors) {
            errors.forEach(({
              field,
              message
            }) => {
              this.$set(this.form.errors, field, message)
            })
          }

          success = false

          return this.$t('global.form.errorMessages.invalid')
        }
      }

      if (this.editing) {
        await this.updateMembership({
          membershipUuid: this.editItem.uuid,
          folderUuid: this.folder,
          validFrom: this.validFromEnabled ? validFrom : null,
          validTo: validTo || null,
          handlers
        })
      } else {
        await this.createMembership({
          folderUuid: this.folder,
          documentUuid: document,
          validFrom: validFrom,
          validTo,
          comment,
          handlers
        })
      }

      if (success) {
        this.closeSidePanel()
      }
    },
    getDocument (documentUuid) {
      return this.documents.find(({ uuid }) => {
        return uuid === documentUuid
      }) ?? null
    },
    resetForm () {
      this.$set(this.form, 'data', {
        document: '',
        validFrom: '',
        validTo: '',
        comment: ''
      })

      this.editItem = null
    },
    handleDelete ({ uuid }) {
      this.deleteItem = uuid
      this.deleteDialog = true
    },
    async confirmDelete () {
      const handlers = {
        '2xx': () => {
          this.resetDeleteDialog()
          this.deleteDialog = false

          this.fetchMemberships()
          this.closeSidePanel()
          return this.$tp('components.memberships.table.actions.messages.delete.success')
        },
        400: () => {
          this.resetDeleteDialog()
          this.deleteDialog = false

          return this.$tp('components.memberships.table.actions.messages.delete.error')
        },
        '4xx': () => {
          this.resetDeleteDialog()
          this.deleteDialog = false

          return this.$tp('components.memberships.table.actions.messages.delete.errorGeneric')
        }
      }

      await this.deleteMembership({ uuid: this.folder, membershipUuid: this.deleteItem, handlers })
    },
    resetDeleteDialog () {
      this.deleteItem = null
    },
    deletionAllowed ({ validFrom }) {
      return !isPast(createValidDateFromApi(validFrom)) && (this.isAdmin || this.isManager)
    },
    editingAllowed ({ validTo }) {
      if (!this.isAdmin && !this.isManager) {
        return false
      }

      if (!validTo) {
        return true
      }

      return !isPast(createValidDateFromApi(validTo))
    },
    handleEdit (item) {
      const { documentHandleUuid, validFrom, validTo, comment } = item

      this.editItem = item

      this.$set(this.form, 'data', {
        document: documentHandleUuid,
        validFrom: format(createValidDateFromApi(validFrom), 'yyyy-MM-dd'),
        validTo: validTo ? format(createValidDateFromApi(validTo), 'yyyy-MM-dd') : '',
        comment: comment
      })

      this.sidePanel = true
    },
    isVersionActive ({ validFrom, validTo }) {
      if (!validTo) {
        return isPast(createValidDateFromApi(validFrom))
      }

      return currentTimeIsWithin(createValidDateFromApi(validFrom), createValidDateFromApi(validTo))
    },
    getTextStyles ({ validFrom, validTo }) {
      if (this.isVersionActive({ validFrom, validTo })) {
        return []
      }

      return ['text--secondary']
    },
    shortenUuid,
    async fetchMemberships () {
      const handlers = {
        '2xx': (response) => {
          this.$set(this, 'memberships', response.data)
        }
      }

      await this.getMemberships({ uuid: this.folder, handlers })
    },
    getDocumentVersionUUID (item) {
      const showAtDateTimeDate = this.showAtDateTime ? new Date(this.showAtDateTime) : new Date()
      const documentHandle = this.getDocument(item.documentHandleUuid)

      if (!documentHandle) {
        return null
      }

      const versions = documentHandle.documentVersions ?? []

      const activeVersion = findLast(versions, ({ validFrom }) => {
        return createValidDateFromApi(validFrom) < showAtDateTimeDate
      })

      return activeVersion?.uuid ?? null
    }
  },
  computed: {
    ...mapGetters('documentFolders', ['loadingMemberships', 'deletingMembership', 'creatingMembership']),
    ...mapState('documentFolders', ['documentFoldersMembershipsSidePanel']),
    ...mapGetters('documents', ['documents']),
    dataTable () {
      return {
        headers: [
          { value: 'name' },
          { value: 'documentHandleUuid' },
          ...(this.showAtDateTime ? [{ value: 'currentDocumentVersion' }] : []),
          { value: 'validFrom' },
          { value: 'validTo' },
          { value: 'comment' },
          { value: 'actions', sortable: false, align: 'end' }
        ],
        items: this.groupedMemberships,
        loading: this.loadingMemberships,
        showSelect: false,
        itemKey: 'uuid',
        disablePagination: true,
        hideDefaultFooter: true,
        disableSort: true,
        itemClass: (item) => {
          if (item.uuid === this.editItem?.uuid) {
            return 'grey lighten-3'
          }

          return ''
        }
      }
    },
    actions () {
      return [
        { icon: this.$icons.mdiPencil, handler: this.handleEdit, disabled: (item) => !this.editingAllowed(item), tooltip: this.editButtonTooltip },
        { icon: this.$icons.mdiDelete, handler: this.handleDelete, disabled: (item) => !this.deletionAllowed(item), tooltip: this.deleteButtonTooltip }
      ]
    },
    formConfig () {
      return [
        {
          name: 'document',
          component: 'v-autocomplete',
          props: {
            itemText: 'name',
            itemValue: 'uuid',
            items: this.documents,
            disabled: this.editing
          }
        },
        {
          name: 'validFrom',
          component: 'v-date-picker',
          props: {
            min: getToday().toString(),
            disabled: !this.validFromEnabled
          }
        },
        {
          name: 'validTo',
          component: 'v-date-picker',
          props: {
            min: getToday().toString()
          }
        },
        {
          name: 'comment',
          component: 'v-textarea'
        }
      ]
    },
    formRules () {
      return {
        document: this.editing ? [] : [required],
        validFrom: this.validFromEnabled ? [
          required,
          [minDate, { min: new Date(), allowSameDate: true }]
        ] : [],
        validTo: this.validFromEnabled ? [
          [minDate, { field: 'validFrom' }]
        ] : [
          required,
          [minDate, { min: new Date(), allowSameDate: true }]
        ],
        comment: []
      }
    },
    groupedMemberships () {
      let filteredMemberships

      if (this.showAtDateTime) {
        const date = new Date(this.showAtDateTime)

        filteredMemberships = [...this.memberships].filter(({ validFrom, validTo }) => {
          const validFromDate = createValidDateFromApi(validFrom)

          if (validTo) {
            const validToDate = createValidDateFromApi(validTo)

            return timeIsWithin(date, validFromDate, validToDate)
          }

          return date >= validFromDate
        })
      } else {
        filteredMemberships = [...this.memberships]
      }

      const sortedMemberships = filteredMemberships.sort((itemA, itemB) => {
        const dateA = createValidDateFromApi(itemA.validFrom)
        const dateB = createValidDateFromApi(itemB.validFrom)

        if (dateA < dateB) {
          return -1
        }

        if (dateA > dateB) {
          return 1
        }

        return 0
      })

      const groups = sortedMemberships.reduce((groups, { uuid, documentHandleUuid, validFrom, validTo, comment }) => {
        const document = this.getDocument(documentHandleUuid)

        if (document !== null) {
          if (!groups[documentHandleUuid]) {
            groups[documentHandleUuid] = []
          }

          const length = groups[documentHandleUuid].length
          const name = length > 0 ? null : document.name

          groups[documentHandleUuid].push({
            uuid,
            documentHandleUuid,
            name,
            validFrom,
            validTo,
            comment
          })

          return groups
        }
      }, {})

      if (groups) {
        return Object.values(groups).flat()
      }
      return []
    },
    addButtonText () {
      return this.$tp('components.memberships.add')
    },
    tableTitle () {
      return 'components.memberships.table.title'
    },
    successMessage () {
      if (this.editing) {
        return this.$tp('components.memberships.form.messages.successEditing')
      }

      return this.$tp('components.memberships.form.messages.success')
    },
    deleteDialogTitle () {
      return this.$tp('components.memberships.table.dialogs.delete.title')
    },
    deleteDialogText () {
      return this.$tp('components.memberships.table.dialogs.delete.text')
    },
    deleteDialogCancelButton () {
      return this.$tp('components.memberships.table.dialogs.delete.buttons.cancel')
    },
    deleteDialogConfirmButton () {
      return this.$tp('components.memberships.table.dialogs.delete.buttons.confirm')
    },
    editing () {
      return this.editItem !== null
    },
    validFromEnabled () {
      return this.editItem === null || createValidDateFromApi(this.editItem.validFrom) > new Date()
    },
    submitText () {
      if (this.editing) {
        return this.$tp('components.memberships.form.buttons.edit')
      }

      return this.$tp('components.memberships.form.buttons.submit')
    },
    showAtDateLabel () {
      return this.$tp('components.memberships.table.showAtDate.label')
    },
    showAtDatePlaceholder () {
      return this.$tp('components.memberships.table.showAtDate.placeholder')
    },
    editButtonTooltip () {
      return this.$tp('components.memberships.table.actions.edit')
    },
    deleteButtonTooltip () {
      return this.$tp('components.memberships.table.actions.delete')
    },
    sidePanel: {
      get () {
        return this.documentFoldersMembershipsSidePanel
      },
      set (value) {
        if (value) {
          this.setDocumentFoldersSidePanel(!value)
        }
        this.setDocumentFoldersMembershipsSidePanel(value)
      }
    }
  },
  inject: [
    '$tp'
  ],
  watch: {
    sidePanel (open) {
      if (!open) {
        // wait for closing animation
        setTimeout(this.resetForm, 200)
      }
    },
    deleteDialog (open) {
      if (!open) {
        this.resetDeleteDialog()
      }
    }
  }
}
</script>
