var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-12"},[_c('help-dialog',{attrs:{"path":_vm.headingPath,"tag":"h2","css-classes":"mb-2"}}),_c('data-table',{attrs:{"table-name":"management.table","data-table":_vm.dataTable,"actions":_vm.actions,"search-action":_vm.searchAction},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row align-center"},[(item.numDocuments === 0)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiAlertCircleOutline)+" ")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.noDocumentsTooltip)}})]):_vm._e(),_c('copy',{attrs:{"text":item.name}})],1)]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('formatted-date',{attrs:{"date":item.createdOn}})]}},{key:"item.updatedOn",fn:function(ref){
var item = ref.item;
return [_c('formatted-date',{attrs:{"date":item.updatedOn}})]}},{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('copy',{attrs:{"text":item.uuid,"display":_vm.shortenUuid(item.uuid)}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[(item.comment)?_c('v-row',[_c('v-col',{staticClass:"body-2 mb-4",attrs:{"cols":"auto","md":"6","lg":"4"}},[_c('span',{staticClass:"font-weight-bold mb-2 d-inline-block"},[_vm._v(_vm._s(_vm.commentHeading))]),_c('p',[_vm._v(_vm._s(item.comment))])])],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('memberships',{attrs:{"folder":item.uuid}})],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[(_vm.deleteFolderItem)?_c('v-card',{staticClass:"px-4 py-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.deleteDialogTitle))]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.resetDeleteDialog}},[_vm._v(_vm._s(_vm.deleteDialogCancelText))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.deleteDialogConfirmText))])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }